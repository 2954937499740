.SubmitButton{
    display: inline-block;

    width: 140px;
    // background-image: linear-gradient(90deg, #BF734F, #F19867, #AF6339);
    text-align: center;
    padding-top: 14px;
    padding-bottom: 14px;
    color: white;
    margin-top: 24px;
    cursor: pointer;
    filter: brightness(1);
    border: 1px solid white;
    border-radius: 20px;
    @include font-body-bold;
    &:hover{
        filter: brightness(1.25);
    }
}