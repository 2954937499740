.Placeholder{
    width: $app-width;
    height: $app-height;
    background-color: #043039;

    font-size: 80px;
    font-weight: 900;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

