.SaleIndicator{
    $w: 56px;
    $h: 56px;

    width: $w !important;
    height: $h !important;
    max-width: $w;
    max-height: $h;
    min-height: $h;
    min-width: $w;
    border-radius: 50%;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    vertical-align: center;
    padding-top: 15px;
    color: white;

    @include font-body;

    position: relative;
    left: -4px;

    &[data-sold='true']{
        background-color: #9D402E;
    }

    &[data-res='true']{
        background-color: #737373;
    }

    div{
        position: relative;
        top: 1px;
        left: 0.5px;
    }
}


.SaleIndicatorJustText{
    position: relative;
    font-weight: 600;
    left: -4px;
    top: -4px;
    color: #492b38;
}