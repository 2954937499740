.Field{
    width: 100%;
    color: #b3c3bd;
    
    &[data-has-errors="true"]{
        .Input{
            border-bottom-color: red;
        }
    }
    
    .Input{
        border-radius: 0px;
        font-weight: 100;
        width: 70%;
        background-color: white !important;
        //border: none !important;
        border-width: 0px !important;
        border-color: rgba(255, 255, 255, 0.1);
        border-bottom-width: 1px !important;
        border-bottom-color: white;
        margin-top: 18px;
        border-radius: 20px;
        @include font-style-floor;
        
        font-size: 22px;
        
        // font-family: inherit !important;
        padding: 14px;
        text-align: center;
        // font-family: 'TTHovesRG';
        // @include font-header;
        color: $color-dark-background;
        

        &:focus{
            &::placeholder{
                @include font-style-floor;

                color: $color-dark-background;
                font-size: 22px;

                visibility: hidden;
            }
        }

        &::placeholder{
            @include font-style-floor;
            font-size: 22px;

            color: $color-dark-background;
            opacity: 0.6;
        }
        
    }
}

