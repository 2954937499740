.App{
    width: 100vw;
    height: 100vh;
}


@font-face {
    font-family: 'OptimaLTPro-Roman';
    src: url("../../fonts/OptimaLTPro-Roman.otf");
}

@font-face {
    font-family: 'FreightSansProLight-Regular';
    src: url('../../fonts/FreightSansProLight-Regular.otf');
}

@font-face {
    font-family: 'TTHovesProTrialRG';
    src: url('../../fonts/TTHovesProTrialRegular.ttf');
}

@font-face {
    font-family: 'TTHovesProTrialThin';
    src: url('../../fonts/TTHovesProTrialThin.ttf');
}

@font-face {
    font-family: 'TTHovesProRG';
    src: url('../../fonts/TTHovesPro-Rg.otf');
}

@font-face {
    font-family: 'TTHovesMD';
    src: url('../../fonts/TTHovesPro-Md.otf');
}


@font-face {
    font-family: 'FreightSansProSemibold-Regular';
    src: url('../../fonts/FreightSansProSemibold-Regular.otf');
}

@mixin font-header {
    font-family: 'TTHovesProTrialRG';
    font-style: normal;
    font-size: 17px;
    letter-spacing: 0.05em;
}

@mixin font-style-floor {
    font-family: 'TTHovesProTrialThin';
    font-style: normal;
    font-size: 17px;
    letter-spacing: 0.5;
}

@mixin font-body {
    font-family: 'TTHovesProRG';
}

@mixin font-body-bold {
    font-family: 'TTHovesMD';
    font-style: normal;
    font-weight: 400;
}