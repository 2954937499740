.LevelSelector{
    width: 300px;
    //background-color: red;
    color: white;
    font-size: 17.5px;
    margin-top: -10px;
    margin-left: 10px;
    cursor: pointer;
    
    .LevelSelectorLine{
        pointer-events: none;
        position: absolute;
    }


    .LevelSelectorItem{
        letter-spacing: 0.05em;
        display: flex;
        height: 47px;
        align-items: center;
        //background-color: green;

        *{
            pointer-events: none;
        }
        
        
        .LevelSelectorLabel{
            width: 100px;
            color: white;
            opacity: 1;
            letter-spacing: 0.05em;
            font-size: 16px;
            @include font-style-floor;
        }

        .TearDropLabel{
            @include font-style-floor;
            color: white;
            letter-spacing: 0.05em;
            font-size: 16px;
        }

        .LevelSelectorIcon{
            position: relative;
            top: 4px;
        }

        .LevelSelectorNumber{
            position: relative;
            top: 2px;
            left: 4px;

            img{
                height: 40px;
                width: auto;
                position: absolute;
                transform: translate(0, -50%) scale(0.75);
            }
            div{
                position:absolute;
                transform: translate(0, -50%);
                left: 18px;
                top: -2px;
                width: 20px;
                text-align: center;
            }
        }
    }
}